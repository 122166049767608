import axios from 'axios';

const url = 'https://api.openweathermap.org/data/2.5/weather';
const API_KEY = 'cef411e8bebc74c23b276865206092c3';

export const fetchWeather = async (query) => {
  const { data } = await axios.get(url, {
    params: {
      q: query,
      units: 'metric',
      APPID: API_KEY,
    },
  });
  return data;
};
